<template>
  <div v-if="toastThankyou" >

    <!-- <div class="absolute top-10 right-5" style="z-index: 10000;">
      <a  @click="close" class="text-white anuphan-medium underline">ปิด</a>
    </div> -->

    <div class="overflow-x-hidden overflow-y-hidden fixed  top-0 bottom-0 left-0 right-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-3xl">
        <div class="border-0 shadow-lg relative flex flex-col w-full bg-white rounded-xl outline-none focus:outline-none">
          <div class="relative flex-auto p-10 w-[350px]">
            <div class="flex flex-col items-center justify-center">
              <img class="w-44 h-auto object-contain" src="~/assets/images/thankyou-received.png" />
              <div class="text-center mt-8 space-y-2">
                <p class="text-2xl font-bold">Thank You</p>
                <p class="text-lg">{{ $t('promptpayAlert.text') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="toastThankyou" class="fixed top-0 bottom-0 left-0 right-0 z-40 bg-black opacity-70"></div>
  </div>
</template>


<script lang="ts" setup>
import { useAppStateStore } from '~/store/appState'

const appStateStore = useAppStateStore()

const toastThankyou = computed(() => appStateStore.toastThankyou)

const close = () => {
  appStateStore.toastThankyou = false
}
</script>

