<template>
  <div v-if="step && step.currentStep" class="bg-white">

    <!-- <div  v-if="step.currentStep && step" class=" bg-app-blue h-1 transition-all ease-out duration-[2000ms] rounded " :class="[width]"></div> -->
    <div class="grid grid-cols-3 gap-1 px-1 py-1">

      <!-- <div class="h-2 rounded-full" :class="step?.currentStep >= 1 ? '' : 'bg-gray-200'"></div>
      <div class="h-2 rounded-full" :class="step?.currentStep >= 2 ? '': 'bg-gray-200'"></div>
      <div class="h-2 rounded-full" :class="step?.currentStep >= 3 ? '' : 'bg-gray-200'"></div> -->
      <div class="h-2 rounded-full bg-gray-200" :style="step?.currentStep >= 1 ? `background-color: ${props?.bgColor}`  : ''"></div>
      <div class="h-2 rounded-full bg-gray-200" :style="step?.currentStep >= 2 ? `background-color: ${props?.bgColor}`  : '' "></div>
      <div class="h-2 rounded-full bg-gray-200" :style="step?.currentStep >= 3 ? `background-color: ${props?.bgColor}`  : '' "></div>
    </div>
  </div>
</template>

<script setup lang="ts">
  const props = defineProps({
    step: { type: Object, default: {} },
    bgColor: { type: String, default: "#0855cb"}
  })

  // const width = ref('w-[33%]')
  // watch(() => props.step.currentStep, (newVal) => {
  //   switch (newVal) {
  //     case 1:
  //       width.value = 'w-[33%]'
  //       break;

  //     case 2:
  //       width.value = 'w-[66%]'
  //       break;
    
  //     case 3:
  //       width.value = 'w-[100%]'
  //       break;
  //   }
  // })


</script>

<style scoped>

</style>