<template>
  <div class="w-full h-[13vh]"  :style="`background-color: ${colors?.background};`">
    <!-- <div class="p-2.5 w-full h-full flex items-center text-app-black" :class="isShowBack ? 'justify-between' : 'justify-end'">
      <button
        v-if="isShowBack"
        id="back-button"
        class="h-10 mr-2 px-5 text-app-blue transition-colors duration-150 bg-white border border-gray rounded-full focus:shadow-outline"
        @click="backPage"
        style="width: 150px;"
      >
        {{ labelBack }}
      </button>

      <button
        v-if="isShowNext"
        id="next-button"
        class="h-10 px-5 text-white transition-colors duration-150 bg-app-blue rounded-full focus:shadow-outline hover:bg-app-blue"
        @click="nextPage"
        style="width: 150px;"
      >
        <i class="fa-regular fa-pen-nib"></i> {{labelNext}} 
      </button>
    </div> -->

    <!-- next only -->
    <div class="p-2.5 px-6 w-full h-full flex justify-center items-center text-app-black">
      <button
        v-if="isShowNext"
        id="next-button"
        class="w-full h-12 px-6 shadow drop-shadow-lg text-white transition-colors duration-150 rounded-full focus:shadow-outline space-x-2"
        :style="`background-color: ${colors?.main};`"
        @click="nextPage"
      >
        <span>{{labelNext}} </span> 
        <span><i class="fa-regular fa-arrow-right"></i></span>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
  const emit = defineEmits(['next', 'back'])

  defineProps({
    isShowNext: { type: Boolean,default: true },
    isShowBack: { type: Boolean,default: true },
    labelBack: {type: String,default: 'กลับ' },
    labelNext: {type: String,default: 'เขียนอวยพร'  },
    colors: { type: Object, default: {}},
  })

  const nextPage = () => {
    emit('next', null)
  }

  const backPage = () => {
    emit('back', null)
  }
</script>